import { render, staticRenderFns } from "./universitySearch.vue?vue&type=template&id=204b5ae6&scoped=true&"
import script from "./universitySearch.vue?vue&type=script&lang=js&"
export * from "./universitySearch.vue?vue&type=script&lang=js&"
import style0 from "./universitySearch.vue?vue&type=style&index=0&id=204b5ae6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204b5ae6",
  null
  
)

export default component.exports